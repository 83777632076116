var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pl-0",
      staticStyle: { "margin-bottom": "20%" },
      style: _vm.showLinkExpiredError ? "min-height:" + _vm.maxHeight : "",
    },
    [
      _c("v-row", { staticClass: "mt-16 mx-auto" }, [
        _c("img", {
          staticClass: "mx-auto",
          attrs: {
            src: require("../../../assets/images/Forgot-password.png"),
            width: "100",
            height: "100",
            role: "img",
            "aria-labelledby": "forgotPasswordText",
          },
        }),
      ]),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLinkExpiredError,
              expression: "showLinkExpiredError",
            },
          ],
          staticClass: "mx-auto mt-12 justify-center align-center",
        },
        [
          _c(
            "v-alert",
            {
              staticClass: "ml-10 mr-10",
              attrs: {
                color: "red",
                dismissible: "",
                text: "",
                icon: "mdi-alert-circle-outline",
                dense: "",
                type: "error",
              },
            },
            [
              _c(
                "span",
                { staticClass: "my-auto font-weight-regular caption" },
                [_vm._v(_vm._s(_vm.$t("passwordLinkExpired")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mt-12" }, [
        _c(
          "div",
          {
            staticClass: "text-h5 font-weight-bold mx-auto",
            attrs: { id: "forgotPasswordText" },
          },
          [_vm._v(" " + _vm._s(_vm.$t("forgotPassword")) + " ")]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "300", elevation: "0" },
            },
            [
              _c("div", { staticClass: "body-2 text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("resetPasswordInstruction")) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: { cols: "10", sm: "8", md: "8", lg: "8" },
            },
            [
              _c("v-text-field", {
                attrs: {
                  "error-messages": _vm.emailErrors,
                  label: _vm.$t("emailAddress"),
                  "prepend-inner-icon": "mdi-account-outline",
                  outlined: "",
                  required: "",
                },
                on: {
                  input: _vm.onEmailInput,
                  blur: function ($event) {
                    return _vm.$v.email.$touch()
                  },
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-none white--text mx-auto rounded-lg",
              attrs: {
                width: "300",
                color: "blue darken-3",
                disabled: _vm.$v.$invalid,
              },
              on: { click: _vm.sendEmail },
            },
            [_vm._v(" " + _vm._s(_vm.$t("submitRequest")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showLinkExpiredError,
              expression: "!showLinkExpiredError",
            },
          ],
          staticClass: "mt-12",
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-auto rounded-lg",
              attrs: { outlined: "", color: "blue" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onBackButtonClick")
                },
              },
            },
            [_vm._v(" Back ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }