<template>
  <v-container
    class="pl-0"
    style="margin-bottom: 20%"
    :style="showLinkExpiredError ? `min-height:${maxHeight}` : ''"
  >
    <v-row class="mt-16 mx-auto">
      <img
        class="mx-auto"
        src="../../../assets/images/Forgot-password.png"
        width="100"
        height="100"
        role="img"
        aria-labelledby="forgotPasswordText"
      />
    </v-row>
    <v-row
      v-show="showLinkExpiredError"
      class="mx-auto mt-12 justify-center align-center"
      ><v-alert
        class="ml-10 mr-10"
        color="red"
        dismissible
        text
        icon="mdi-alert-circle-outline"
        dense
        type="error"
      >
        <span class="my-auto font-weight-regular caption">{{
          $t("passwordLinkExpired")
        }}</span>
      </v-alert></v-row
    >
    <v-row class="mt-12">
      <div id="forgotPasswordText" class="text-h5 font-weight-bold mx-auto">
        {{ $t("forgotPassword") }}
      </div></v-row
    >
    <v-row class="mt-10"
      ><v-card max-width="300" elevation="0" class="mx-auto">
        <div class="body-2 text-center">
          {{ $t("resetPasswordInstruction") }}
        </div>
      </v-card>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="10" sm="8" md="8" lg="8" class="mx-auto">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          :label="$t('emailAddress')"
          prepend-inner-icon="mdi-account-outline"
          outlined
          required
          @input="onEmailInput"
          @blur="$v.email.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-btn
        width="300"
        color="blue darken-3"
        class="text-none white--text mx-auto rounded-lg"
        :disabled="$v.$invalid"
        @click="sendEmail"
      >
        {{ $t("submitRequest") }}
      </v-btn>
    </v-row>
    <v-row v-show="!showLinkExpiredError" class="mt-12">
      <v-btn
        outlined
        color="blue"
        class="mx-auto rounded-lg"
        @click="$emit('onBackButtonClick')"
      >
        Back
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ERRORS from "@/static/errors.json";
import { getKeys } from "@/store/utils/utils";
export default {
  name: "PasswordMain",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  data: () => ({
    email: "",
    showInvalidEmailError: false,
    showLinkExpiredError: false,
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("emailValidError"));
      !this.$v.email.required && errors.push(this.$t("emailRequiredError"));
      this.showInvalidEmailError && errors.push(this.$t("emailNotFound"));
      return errors;
    },
    maxHeight() {
      return this.$vuetify.breakpoint.mobile ? "750px" : "700px";
    },
  },
  created() {
    const urlParams = new URLSearchParams(location.search);
    const keys = getKeys(urlParams);
    if (keys.length && keys.includes("passwordLinkInvalid")) {
      this.showLinkExpiredError = true;
    }
  },
  methods: {
    onEmailInput() {
      this.$v.email.$touch();
      this.showInvalidEmailError = false;
    },
    async sendEmail() {
      const varificationStatus = await this.$store.dispatch(
        "sendPasswordMail",
        {
          email: this.email,
        },
      );
      if (
        varificationStatus.status === "error" &&
        varificationStatus.error === ERRORS.INVALID_USER
      ) {
        this.showInvalidEmailError = true;
      } else {
        this.$emit("emailSent", this.email);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
